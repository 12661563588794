import { response as apiResponse, ResponseType, states } from '@8ms/helpers/dist/api';
import { Password } from './constants';

/**
 * Validation for the password.
 */
const isPasswordValid = (input: string): ResponseType =>
{
	let response: ResponseType = {
		...apiResponse,
		state: states.SUCCESS,
	};
	
	const symbolRegex = new RegExp('(?=.*[!@#$%^&*])');
	
	if (input.length < Password.minLength)
	{
		response.error = `Minimum password length is ${Password.minLength}.`;
		response.state = states.ERROR;
	}
	
	// Requires a symbol
	else if (Password.requiresSymbol && !symbolRegex.test(input))
	{
		response.error = `The password must have a symbol (!@#$%^&*).`;
		response.state = states.ERROR;
	}
	
	return response;
}

export default isPasswordValid;
