import { Alert } from '@components/Alert';
import { ResponseType, states } from '@8ms/helpers/dist/api';
import { idleState } from '@core/helpers/ajax';

export type AjaxAlertType = {
	response: ResponseType,
	setAjaxAlert: Function,
}

const AjaxAlert = ({response, setAjaxAlert}: AjaxAlertType) =>
{
	if (states.IDLE === response.state)
	{
		return <></>;
	}
	
	return (
		<Alert
			onClose={event => setAjaxAlert(idleState)}
			variant={response.state}
		>
			{
				states.ERROR &&
				<span>{response.error}</span>
			}
			{
				states.SUCCESS &&
				'string' === typeof response.body &&
				<span>{response.body}</span>
			}
		</Alert>
	);
}

export default AjaxAlert;
