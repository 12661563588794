import { states, StatesType } from '@8ms/helpers/dist/api';
import { CheckIcon, WarningTwoIcon } from '@chakra-ui/icons'
import { Alert as ChakraAlert, AlertDescription, Box, CloseButton, Flex } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

export type AlertType = {
	children?: ReactNode,
	overrides?: {
		alert?: object,
		close?: object,
		description?: object,
	},
	onClose?: Function,
	variant: StatesType,
}

const Alert = ({children, onClose, overrides, variant}: AlertType) =>
{
	const [show, setShow] = useState(true);
	
	if (!show)
	{
		return <></>;
	}
	
	if (undefined === children)
	{
		return <></>;
	}
	
	const AlertIcon = variant =>
	{
		switch (variant)
		{
			case states.ERROR:
				return <WarningTwoIcon/>;
			
			case states.SUCCESS:
				return <CheckIcon/>;
			
			default:
				return <></>;
		}
	}
	
	const handleClose = () =>
	{
		if (undefined !== onClose)
		{
			onClose();
		}
		
		setShow(false);
	}
	
	return (
		<div className='my-[0.5rem]'>
			<ChakraAlert
				variant={variant}
				{...overrides.alert}
			>
				<Box
					alignSelf='start'
					display='flex'
					mr='0.5rem'
					pt='0.25rem'
				>
					<AlertIcon variant={variant}/>
				</Box>
				<Flex
					flex='1'
				>
					<AlertDescription {...overrides.description}
					>
						{children}
					</AlertDescription>
				</Flex>
				<Box alignSelf='start'>
					<CloseButton
						onClick={handleClose}
						{...overrides.close}
					/>
				</Box>
			</ChakraAlert>
		</div>
	);
}

Alert.defaultProps = {
	children:  undefined,
	onClose:   undefined,
	overrides: {},
	variant:   states.IDLE,
}

export default Alert;
