import { config } from '@core/app/config';
import { logIn } from '@components/redux/slices/auth';
import { getFormattedEmail, isEmailValid, isPasswordValid } from '@core/app/user';
import { AjaxAlert } from '@components/Alert';
import { Button } from '@components/Button';
import { errorResponse, idleState } from '@core/helpers/ajax';
import { getLayout as getLayoutLoggedIn } from '@layouts/LoggedIn';
import { states } from '@8ms/helpers/dist/api';
import { post } from '@8ms/helpers/dist/axios';
import { getEncrypt, getRandom } from '@8ms/helpers/dist/crypto';
import * as CryptoJS from 'crypto-js';
import { NextLayoutPage } from 'next';
import Image from 'next/image'
import NextLink from 'next/link';
import { useRouter } from 'next/router'
import { setCookie } from 'nookies';
import * as OTPAuth from 'otpauth';
import { useState } from 'react';
import { QRCode } from "react-qr-svg";
import { useDispatch } from 'react-redux';

const STATE_CREDENTIALS  = 'credentials';
const STATE_TFA_SETUP    = 'tfaSetup';
const STATE_TFA_VALIDATE = 'tfaValidate';

const PageIndex: NextLayoutPage = () =>
{
	const dispatch                      = useDispatch();
	const router                        = useRouter();
	const [state, setState]             = useState(STATE_CREDENTIALS);
	const [userId, setUserId]           = useState(0);
	const [email, setEmail]             = useState('');
	const [password, setPassword]       = useState('');
	const [tfaSecret, setTfaSecret]     = useState('');
	const [tfaSetup, setTfaSetup]       = useState('');
	const [tfaValidate, setTfaValidate] = useState('');
	const [ajaxAlert, setAjaxAlert]     = useState(idleState);
	
	const totpConfig = {
		issuer:    '8MS',
		label:     'Health Check',
		algorithm: 'SHA512',
		digits:    6,
		period:    30,
		secret:    OTPAuth.Secret.fromUTF8(tfaSecret),
	};
	
	const totp = new OTPAuth.TOTP(totpConfig);
	
	/**
	 * Reset Password
	 */
	const handleResetPassword = async (event) =>
	{
		event.preventDefault();
		
		const emailValid = isEmailValid({input: email});
		
		// Check email validation
		if (states.ERROR === emailValid.state)
		{
			setAjaxAlert(emailValid);
		}
		
		else
		{
			await post({
				data:      {
					input: {
						email: email,
					},
				},
				onError:   response => setAjaxAlert({...errorResponse, error:response}),
				onSuccess: response =>
				           {
					           setAjaxAlert({body: response, error: '', state: states.SUCCESS});
				           },
				url:       '/api/auth/emailResetPassword',
			});
		}
		
		return false;
	}
	
	const handleSubmit = async (event) =>
	{
		event.preventDefault();
		
		// Credential Authentication
		if (STATE_CREDENTIALS === state)
		{
			const emailValid    = isEmailValid({input: email});
			const passwordValid = isPasswordValid(password);
			
			// Check email validation
			if (states.ERROR === emailValid.state)
			{
				setAjaxAlert(emailValid);
			}
			
			// Check password validation
			else if (states.ERROR === passwordValid.state)
			{
				setAjaxAlert(passwordValid);
			}
			
			// Password is valid
			else
			{
				// Create a salt to encode the user's password
				const passwordSalt = getRandom({length: 8});
				
				// Encode the password using the salt
				const encodedPassword = getEncrypt({
					CryptoLib: CryptoJS,
					appSalt:   config.dashboard.id,
					input:     password,
					salt:      passwordSalt,
				});
				
				await post({
					data:      {
						input: {
							email:           email,
							encodedPassword: encodedPassword,
							salt:            passwordSalt,
						},
					},
					onError:   response => setAjaxAlert({...errorResponse, error:response}),
					onSuccess: response =>
					           {
						           setAjaxAlert(idleState);
						
						           // if (states.SUCCESS === response.state)
						           //{
						           // Update the user id
						           setUserId(response.id);
						
						           // User needs to setup TFA
						           if (null === response.tfa)
						           {
							           const genTfaSecret = getRandom({length: 8});
							           setTfaSecret(genTfaSecret);
							           setState(STATE_TFA_SETUP);
						           }
						
						           // User needs to verify TFA
						           else
						           {
							           setTfaSecret(response.tfa);
							           setState(STATE_TFA_VALIDATE);
						           }
						           // }
					           },
					url:       '/api/auth/checkCredentials',
				});
			}
		}
		
		// Two Factor Authentication
		else
		{
			// Check if Tfa is valid
			const isTfaSetupValid    = STATE_TFA_SETUP === state && totp.generate() === tfaSetup;
			const isTfaValidateValid = STATE_TFA_VALIDATE === state && totp.generate() === tfaValidate;
			
			// If set up TFA or validating TFA is not valid
			if (!isTfaSetupValid && !isTfaValidateValid)
			{
				setAjaxAlert({
					body:  null,
					error: 'One time code does not match, please try again.',
					state: states.ERROR,
				});
			}
			
			// Success
			else
			{
				await post({
					data:      {
						input: {
							action:    state,
							userId:    userId,
							tfaSecret: tfaSecret,
						},
					},
					onError:   response => setAjaxAlert({...errorResponse, error:response}),
					onSuccess: response =>
					           {
						           setAjaxAlert(idleState);
						
						           // Set the cookie (expires in 1 hour)
						           setCookie(null, config.cookies.auth, response.cookie.token, {
							           maxAge: 1 * 60 * 60,
							           path:   '/',
						           });
						
						           dispatch(logIn(response));
						
						           router.push({pathname: config.dashboard.home});
					           },
					url:       '/api/auth/checkTfa',
				});
			}
		}
	}
	
	return (
		<div className='container'>
			<div className='row'>
				<div className='column text-center mt-4 md:w-6/12 md:ml-3/12 lg:w-4/12 lg:ml-4/12'>
			        <div className='self-center mt-8 p-8'>
				        <div className='mb-16'>
					        <NextLink href='/'>
						        <a>
							        <Image
								        alt='BBC News'
								        src='/logos/bbc.svg'
								        width={148}
								        height={148}
							        />
						        </a>
					        </NextLink>
				        </div>
				        <h1 className='h2'>{config.dashboard.name}</h1>
				        <AjaxAlert
					        response={ajaxAlert}
					        setAjaxAlert={setAjaxAlert}
				        />
				        <div className='mt-8'>
							<form onSubmit={handleSubmit}>
								 <div>
									{
										STATE_CREDENTIALS === state &&
										<>
											<div
												role='group'
												className='w-full relative text-left'
											>
					                            <label>Email</label>
							                    <input
								                    className='block w-full mt-[10px]'
								                    autoComplete='username'
								                    id='login-username'
								                    onChange={e => setEmail(getFormattedEmail(e.target.value))}
								                    name='email'
								                    type='email'
								                    value={email}
							                    />
					                        </div>
											<div
												role='group'
												className='w-full relative text-left mt-[24px]'
											>
					                            <label>Password</label>
					                            <input
						                            className='block w-full mt-[10px]'
						                            autoComplete='current-password'
						                            id='login-password'
						                            name='password'
						                            onChange={e => setPassword(e.target.value)}
						                            type='password'
						                            value={password}
					                            />
					                        </div>
										</>
									}
									 {
										 STATE_TFA_SETUP === state &&
										 <div
											 role='group'
											 className='w-full relative text-left mt-[24px]'
										 >
				                            <label>Setup Two Factor Authentication (2FA)</label>
											<br/><br/>
											<div>
												<QRCode
													style={{width: 160}}
													value={totp.toString()}
												/>
											</div>
											<br/><br/>
				                            <input
					                            autoComplete='one-time-code'
					                            className='block w-full mt-[10px]'
					                            id='login-tfa-setup'
					                            name='tfaSetup'
					                            onChange={e => setTfaSetup(e.target.value)}
					                            type='text'
					                            value={tfaSetup}
				                            />
				                        </div>
									 }
									 {
										 STATE_TFA_VALIDATE === state &&
										 <div
											 role='group'
											 className='w-full relative text-left mt-[24px]'
										 >
				                            <label>Validate Two Factor Authentication (2FA)</label>
				                            <input
					                            autoComplete='one-time-code'
					                            className='block w-full mt-[10px]'
					                            id='login-tfa-validate'
					                            name='tfaValidate'
					                            onChange={e => setTfaValidate(e.target.value)}
					                            type='text'
					                            value={tfaValidate}
				                            />
				                        </div>
									 }
								</div>
								<div className='mt-8'>
									{
										STATE_CREDENTIALS === state &&
										<>
											<Button
												onClick={handleSubmit}
												type='submit'
											>Log in</Button>
											<Button
												marginLeft='1rem'
												onClick={handleResetPassword}
												variant='secondary'
											>Reset Password</Button>
										</>
									}
									{
										STATE_TFA_SETUP === state &&
										<Button
											onClick={handleSubmit}
											type='submit'
										>Setup TFA</Button>
									}
									{
										STATE_TFA_VALIDATE === state &&
										<Button
											onClick={handleSubmit}
											type='submit'
										>Validate 2FA</Button>
									}
								</div>
			                </form>
			            </div>
			        </div>
			</div>
		</div>
	</div>
	);
}

PageIndex.getLayout = getLayoutLoggedIn;

export default PageIndex;
