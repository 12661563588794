import { SingleOptionType } from '@core/app/dropdown';
import { buildOption } from '@core/helpers/dropdown';
import { UserRole } from '@prisma/client';

export const Password = {
	minLength:      6,
	requiresSymbol: true,
}

export const Roles = [
	UserRole.USER,
	UserRole.ADMIN,
];

export const RoleRequiredOptions: SingleOptionType[] = Roles.map(entry => { return buildOption(entry)});
