import { ResponseType, states } from '@8ms/helpers/dist/api';

/**
 * Default idle response used in React UseState
 */
export const idleState: ResponseType = {
	body:  null,
	state: states.IDLE,
	error: '',
}

/**
 * Default AJAX response when an unexpected error occurred
 */
export const errorResponse: ResponseType = {
	body:  null,
	state: states.ERROR,
	error: 'An unexpected error occurred, please try again.',
}

/**
 * Default AJAX response, show an alert as an unhandled error occurred
 */
export const defaultResponse: ResponseType = {
	body:  null,
	state: states.ERROR,
	error: 'An error occurred, please try again.',
}
