import { response as apiResponse, ResponseType, states } from '@8ms/helpers/dist/api';

const isEmailValid = ({input}: { input: string }): ResponseType =>
{
	let response: ResponseType = {
		...apiResponse,
		body:  '',
		state: states.SUCCESS,
	};
	
	const emailRegex = new RegExp('(?=.*[!@#$%^&*])');
	
	// Requires a symbol
	if (!emailRegex.test(input))
	{
		response.error = `Please provide a valid email address.`;
		response.state = states.ERROR;
	}
	
	return response;
}

export default isEmailValid;
